import backgroundImages from './backgrounds/*.jpg';

const IMAGE_DISPLAY_DURATION = 10000;

// add and cycle background images
const content = document.getElementById("content");

const imageElements = Object.values(backgroundImages).map((file) => {
  const div = document.createElement('div');
  div.setAttribute('class', 'background-image');
  div.setAttribute('style', `background-image: url(${file});`);
  document.body.insertBefore(div, content);
  return div;
});

let currentImage = 0;
const showNextImage = () => {
  imageElements.forEach((imageElement, index) => {
    if (currentImage % imageElements.length === index) {
      imageElement.setAttribute('class', 'background-image current');
    } else {
      imageElement.setAttribute('class', 'background-image hidden');
    }
  });
  currentImage++;
}
setInterval(showNextImage, IMAGE_DISPLAY_DURATION);
showNextImage();

// remove before dots on services for first in line
const resetServiceDots = () => {
  let offset = 0;
  const listItems = document.querySelectorAll(".services li");

  const fixDot = (listItem) => {
    console.log(listItem.textContent, listItem.offsetTop)
    if (listItem.offsetTop !== offset) {
      offset = listItem.offsetTop;
      listItem.setAttribute('class', 'first-in-line');
    } else {
      listItem.setAttribute('class', '');
    }
  }

  listItems.forEach(fixDot);
}

window.addEventListener('load', resetServiceDots);
window.addEventListener('resize', resetServiceDots, { passive: true });